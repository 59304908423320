export default {

  /*showNotification_(lng_key){
    this.$buefy.notification.open({
        duration: 4000,
        message: this.$t(lng_key),
        position: 'is-top',
        type: 'is-success'
    })
  },

  showNotificationText_(text){
    this.$buefy.notification.open({
        duration: 4000,
        message: text,
        position: 'is-top',
        type: 'is-success'
    })
  },

  showErrorNotification_(lng_key){
    this.$buefy.notification.open({
        duration: 4000,
        message: lng_key ? this.$t(lng_key) : 'Server error',
        position: 'is-top',
        type: 'is-danger'
    })
  },

  showErrorSubNotification(obj_field,lng_key){
    this.validationErrors[obj_field] = { message: this.$t(lng_key) };
  },*/

  makeClone(obj) {
    return JSON.parse(JSON.stringify(obj))
    let clone = {};
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if ("object"===typeof obj[prop])
          clone[prop] = this.makeClone(obj[prop]);
        else clone[prop] = obj[prop];
      }
    }
    return clone;
  }

}